




import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc';

@Component({
  methods: {
    ...mapActions({
      oidcSignInCallback: 'oidc/oidcSignInCallback',
    }),
  },
})
export default class OidcCallback extends Vue {
  private oidcSignInCallback!: (url?: string) => Promise<string>;

  async created(): Promise<void> {
    try {
      if (this.inIframe()) {
        await vuexOidcProcessSilentSignInCallback();
      } else {
        const redirectPath = await this.oidcSignInCallback();
        this.$router.replace(redirectPath).catch(() => {
          // ignore
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      this.$router.push(this.Routes.Unauthorized);
    }
  }

  private inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
